<template>
  <div class="content md no-padding flex-box vertical">
    <ServiceForm ref="addForm" :initial="initial" class="add-form-box"></ServiceForm>
    <a-space class="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import { randomString } from "../../../common/js/tool";
import ServiceForm from "./components/ServiceForm.vue";

export default {
  name: "ServiceAdd",
  components: {
    ServiceForm,
  },
  data() {
    return {
      initial: null,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.getInitial();
  },
  methods: {
    getInitial() {
      // 复制
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-service/${this.id}`,
          method: "GET",
          params: {
            expand: "serviceType,volunteerGroup,volunteer,serveClasses,servePost,joinGroup",
          },
        }).then((res) => {
          this.initial = res;
          delete this.initial.id;
          delete this.initial.edit_status;
        });
      } else {
        this.initial = {
          serveClasses: [{ key: randomString(16) }],
          servePost: [{ key: randomString(16) }],
          location: "浙江省",
        };
      }
    },
    handleCancel() {
      this.$router.back();
    },
    handleConfirm() {
      this.$refs.addForm.validate().then((form) => {
        this.$axios({
          url: "/admin/volunteer-service",
          method: "POST",
          data: form,
        }).then(() => {
          this.$message.success("添加成功");
          this.$router.back();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
</style>
